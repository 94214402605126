<template>
	<div id="schooling">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">授课列表</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
        <v-search>
          <el-form inline>
            <el-form-item label="校区">
              <el-select size="small" v-model="searchForm.campus_id">
                <el-option :value="0" label="全部" />
                <template v-for="item in campus">
                  <el-option :label="item.name" :value="item.id" />
                </template>
              </el-select>
            </el-form-item>

            <el-form-item label="学期">
              <el-select size="small" v-model="searchForm.semester_id">
                <el-option :value="0" label="全部" />
                <template v-for="item in semesters">
                  <el-option :label="item.name" :value="item.id" />
                </template>
              </el-select>
            </el-form-item>

            <el-form-item label="班级">
              <el-select size="small" v-model="searchForm.class_id">
                <el-option :value="0" label="全部" />
                <template v-for="item in classListOptions">
                  <el-option :label="item.name" :value="item.id" />
                </template>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-input size="small" style="margin-right: 10px;" placeholder="输入老师姓名搜索"  prefix-icon="el-icon-search" v-model="searchForm.key"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button size="small" icon="el-icon-search" @click="onSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </v-search>

				<div class="title u-f-item u-f-jsb">
					<div class="u-f-item">
						<el-button size="small" @click="$router.push('/schoolings/arrange')" type="primary">授课安排</el-button>
            <v-import :download-template="downloadTemplateUrl" style="padding-left: 10px" @success="onImportCallback">
              <el-button size="small">导入</el-button>
            </v-import>
					</div>
				</div>
				<el-table :data="StaffList" v-loading="loading" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
          <el-table-column prop="semester.name" label="学期" align="center"></el-table-column>
          <el-table-column label="授课校区" align="center">
            <template v-slot="{ row }">
              <span v-if="row.campus">{{row.campus.name}}</span>
              <span v-else>全部</span>
            </template>
          </el-table-column>
          <el-table-column prop="grade_class.name" label="班级" align="center"></el-table-column>
          <el-table-column prop="info.username" label="授课教师" align="center"></el-table-column>
					<el-table-column prop="discipline.name" label="担任课程" align='center'></el-table-column>
					<el-table-column prop="week_hour" label="周课时" width="150" align='center'></el-table-column>
					<el-table-column label="操作" width="150" align="center">
						<template v-slot="scope">
              <el-button @click="delData(scope.row.id)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import v2Teacher from "@/api/v2/teacher";
import VImport from "@/components/import";
import VSearch from "@/components/search";
export default {
	components: {
    VSearch,
    VImport,
		index
	},
	data() {
		return {
			class_id:'',
			StaffList: [],
			addSubject: false,
			loading: false,
			editid: '',
			page: 1,
			limit: 11,
			total: 0,
			school_campus_id: '',
			showTitle: '授课安排',
			showType: 1,
			teacher_list: [],
			key: '',
			typeIndex: 1,
			dataIndex: 0,
			administrators_id: '',
			nums: [],
			selectShow: false,
			selectList: [],
			isBnt:false,
			schoolList:[],
			subjects_arr:[],
			subjectList:[],
			classList:[],
			yearId:'',
			yearList:[],
			week_hour:30,
			addVisible:false,
			file: '',
      search: '',
      downloadTemplateUrl: v2Teacher.importTemplate(),
      searchForm: {
        campus_id: 0,
        semester_id: 0,
        class_id: 0,
        key: '',
      }
		};
	},
  computed: {
    campus() {
      return this.$store.getters['common/campus']
    },
    semesters() {
      return this.$store.getters['common/semesters']
    },
    classListOptions() {
      if (this.searchForm.campus_id) {
        return this.classList.filter(_ => _.school_campus_id === this.searchForm.campus_id)
      }
      return this.classList
    }
  },
	mounted() {
		this.getInfo();
		this.teacherList();
		this.schoolCampusIndex()
		this.getSubjectList()
		this.classData()
		this.getYearList()
	},
	methods: {
		getFile(e){
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		// Import
		Import(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			this.$api.setting.importTeaching(formData).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('导入成功');
					this.addVisible = false
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 下载导入模板
		downModal(){
      window.open(v2Teacher.importTemplate())
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
				}
			});
		},
		// 班级列表
		classData() {
			this.$api.setting
				.getClassList({})
				.then(res => {
					this.classList = res.data.data.rows;
				});
		},
		// 学科列表
		getSubjectList(){
			this.$api.setting.subjectList({}).then(res=>{
				if(res.data.code==1){
					this.subjectList = res.data.data;
				}
			})
		},
		handleClose1(done){
			this.typeIndex = 0;
			this.dataIndex = 0;
			done()
		},
		// 校区信息
		schoolCampusIndex() {
			this.$api.setting
				.schoolCampusIndex({})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.schoolList = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 重置
		reset() {
			this.administrators_id = '';
			this.teacherList();
		},
		// 选择老师
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			this.administrators_id = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		twacherTap(index) {
			this.MeunIndex = index;
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list[i].data[j].teacher[t].teacher_id);
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		closeShow() {
			this.init();
			this.addSubject = false;
		},
		openDialog(type, item) {
			this.showType = type;
			if (type == 1) {
				this.showTitle = '授课安排';
			} else if (type == 2) {
				this.showTitle = '编辑授课';
				this.editid = item.id;
				this.school_campus_id = item.school_campus_id;
				this.administrators_id = item.teacher_id;
				this.yearId = item.year_id;
				this.class_id = Number(item.class_id);
				this.week_hour = item.week_hour;
				this.subjects_arr = item.school_discipline_id;
			}
			this.addSubject = true;
		},
		init() {
			this.school_campus_id = '';
			this.administrators_id = '';
			this.yearId = '';
			this.subjects_arr = '';
			this.class_id = '';
			this.week_hour = 1;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo();
		},
		// 教研室列表
		getInfo() {
      this.loading = true
      v2Teacher
				.getList({
					page: this.page,
					limit: this.limit,
          ...this.searchForm,
				})
        .finally(() => this.loading = false)
				.then(res => {
          let list = res.data.rows;
          this.total = res.data.total;
          this.StaffList = list;
				});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init();
			done();
		},
		// 课程安排
		submit() {
			if(this.showType===1){
				this.addStaff()
			}else{
				this.edit()
			}
		},
		addStaff(){
      v2Teacher
				.add({
          user_id: this.administrators_id,
          discipline_ids: this.subjects_arr,
          campus_ids: this.school_campus_id,
					week_hour:this.week_hour
				})
				.then(res => {
          this.$message.success('添加成功');
          this.init();
          this.addSubject = false;
          this.getInfo();
				});
		},
		edit() {
      console.log('f', {
        user_id: this.administrators_id,
        discipline_ids: this.subjects_arr,
        campus_ids: this.school_campus_id,
        week_hour:this.week_hour,
        id:this.editid
      })
      return
      v2Teacher
				.edit({
          user_id: this.administrators_id,
          discipline_ids: this.subjects_arr,
          campus_ids: this.school_campus_id,
          week_hour:this.week_hour,
					id:this.editid
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
    delData(id) {
      this.$confirm('确定要删除？', '提示').then(() => {
        v2Teacher.delete(id)
            .then(() => {
              this.$message.success('删除成功')
              this.getInfo()
            })
      })
    },
    onSearch() {
      this.getInfo()
    },
    onImportCallback(file, callback) {
      v2Teacher.import(file).then(() => {
        this.$message.success('导入成功')
        this.getInfo()
        callback()
      })
    }
	}
};
</script>

<style scoped lang="scss">
#schooling {
	.content {
		margin-top: 15px;
		background-color: #ffffff;
		padding: 0 25px;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table {
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
