<template>
  <div style="display: inline" class="import-button">
    <div @click="visible = true">
      <slot />
    </div>
    <el-dialog title="数据导入" :visible.sync="visible" width="500px" @close="onClose">
      <div class="update_box">
        <div class="update_item" v-if="downloadTemplate">
          <div style="margin-top: 20px">请按照模板格式进行录入</div>
          <div class="u-f-item" style="margin-top: 5px;">
            <!-- <img src="../../../dist/static/image/excel.png" style="width: 18px;margin-right: 3px;" /> -->
            <i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
            <a href="javascript:;" @click="onClickDownloadTemplate">下载模板</a>
          </div>
        </div>
        <div class="u-f" style="margin-top: 20px;">
          <div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据表格</div>
          <div>
            <el-button size="mini" style="position: relative;" @click="onSelectFile">点击上传</el-button>
            <div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
              <div class="u-f-item">
                <i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
              </div>
              <i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer u-f-item u-f-jsb">
            <el-button @click="visible=false" size="small">取 消</el-button>
            <el-button type="primary" size="small" @click="onConfirm">确 定</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'VImport',
  props: {
    downloadTemplate: [String, Function],
    onImportRequest: Function
  },
  data() {
    return {
      visible: false,
      file: {}
    }
  },
  watch: {
    visible(value) {
      if (! value) {
        this.file = {}
      }
    }
  },
  methods: {
    onClose() {
      this.visible = false
    },
    async onConfirm() {
      if (this.file.size === undefined) {
        this.$message.error('请上传文件')
        return
      }
      if (this.onImportRequest) {
        const load = this.$loading({ text: '导入中' })
        await this.onImportRequest(this.file).finally(() => load.close())
        this.$message.success('导入成功')
        this.visible = false
        return
      }
      this.$emit('success', this.file, () => {
        this.file = {}
        this.visible = false
      })
    },
    // 下载模板
    async onClickDownloadTemplate() {
      let templateUrl
      if (typeof this.downloadTemplate == 'function') {
        this.$downloadFile(this.downloadTemplate)
        return
      } else {
        templateUrl = this.downloadTemplate
      }
      location.href = templateUrl
    },
    onSelectFile() {
      const file = document.createElement('input')
      file.setAttribute('type', 'file')
      file.onchange = (evt) => {
        this.file = evt.target.files[0]
        console.log('f', this.file)
      }
      file.click()
    },
    delFile() {
      this.file = {}
    }
  }
}
</script>

<style scoped lang="scss">
.upload-file {
  display: none;
}
.import-button {
  margin-left: 10px;
}
</style>
