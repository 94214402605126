<template>
  <div class="search">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VSearch'
}
</script>

<style scoped lang="scss">
.search {
  padding: 20px 20px 0;
}
</style>