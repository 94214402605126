import axios from "@/utils/http";
import base from "@/api/base";
import request from "@/utils/request";

const v2Teacher = {
    importTemplate() {
        return base.host + '/template/授课安排导入模板.xlsx'
    },
    import(file) {
        const data = new FormData()
        data.append('file', file)
        return request({
            url: '/v2/school_api/arrange/teacher/import',
            method: 'post',
            data,
        })
    },
    getList(params) {
        return request({
            url: '/v2/school_api/arrange/teacher/index',
            params,
        })
    },
    // 获取老师信息
    getInfo(id) {
        return request({
            url: '/v2/school_api/teacher/get',
            params: {
                id,
            },
        })
    },
    // 获取部门下老师
    getListByDepartment(id, search) {
        return request({
            url: '/v2/school_api/teacher/by_department',
            params: {
                department_id: id,
                search,
            }
        })
    },
    add(data) {
        return request({
            url: '/v2/school_api/arrange/teacher/add',
            method: 'post',
            data,
        })
    },
    edit(data) {
        return request({
            url: '/v2/school_api/arrange/teacher/edit',
            method: 'post',
            data,
        })
    },
    delete(id) {
        return request({
            url: '/v2/school_api/arrange/teacher/del',
            method: 'post',
            data: {
                id,
            }
        })
    }
}
export default v2Teacher
